import React, { useEffect, useRef } from 'react'

import { Alert } from '@mui/material'
import Button from '@mui/material/Button'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { BasicType, BasicTypeWithID } from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { SectionEdit } from '@pages/employee/Profile/Partials/SectionEdit'
import { filterEmptyObjects, getRandomID } from '@pages/employee/Profile/utils'
import { Icon } from '@sharedComponents/React'

import {
	deleteInlineIcon,
	EditContentWrapper,
	EditItem,
	infoAlert,
} from '@pages/employee/Profile/Profile.Style'

interface Props {
	close: () => void
	skills: BasicType[]
	returnData?: (data: BasicType[]) => void
}

const emptySkill: BasicType = {
	value: null,
	title: '',
}

const SkillsEdit = ({ close, skills, returnData }: Props) => {
	const { currentValue, saveData, errors, addItem, removeItem, handleChange } =
		useEditSection('skills', skills.length ? skills : [emptySkill])
	const itemsToRender = useRef<BasicTypeWithID[]>(
		currentValue.map(value => ({
			...value,
			renderID: getRandomID(),
		})),
	)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const itemAdded = useRef<boolean>(false)

	const handleSave = async () => {
		let shouldClose = false
		if (returnData) {
			returnData(filterEmptyObjects(currentValue))
			shouldClose = true
		} else {
			shouldClose = await saveData()
		}

		if (shouldClose) {
			close()
		}
	}

	useEffect(() => {
		if (!itemAdded.current) return

		buttonRef.current.scrollIntoView({
			behavior: 'smooth',
		})
		itemAdded.current = false
	}, [currentValue])

	return (
		<SectionEdit header='Umiejętności' closeFn={ close } saveFn={ handleSave }>
			<EditContentWrapper smallPaddingTop>
				<Alert severity='info' classes={{ standardInfo: infoAlert }}>
					Wpisuj każdą umiejętność osobno
				</Alert>

				{ itemsToRender.current.map((skill, index) => (
					<EditItem key={ skill.renderID }>
						<AutocompleteField
							multiline
							type='skill'
							label=''
							dataValue={ skill }
							error={ errors && errors?.[index]?.title }
							handleChange={ (value: BasicType) => {
								handleChange(value, `[${ index }]`)
							} }
						/>

						<RoundedButton
							icon='trash-can'
							color='error'
							onClick={ () => {
								removeItem(index)
								itemsToRender.current.splice(index, 1)
							} }
							className={ deleteInlineIcon }
						/>
					</EditItem>
				)) }

				<Button
					ref={ buttonRef }
					onClick={ () => {
						addItem(emptySkill)
						itemsToRender.current.push({
							...emptySkill,
							renderID: getRandomID(),
						})
						itemAdded.current = true
					} }
					startIcon={ <Icon type='plus' /> }
				>
					Dodaj umiejętność
				</Button>
			</EditContentWrapper>
		</SectionEdit>
	)
}

export default SkillsEdit
