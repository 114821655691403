import React, { useState } from 'react'

import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Switch,
} from '@mui/material'
import { updateProfile } from '@pages/employee/Profile/api'
import { useGetActualCompletionScore } from '@pages/employee/Profile/hooks/useGetActualCompletionScore'
import {
	BasicType,
	Degree,
	Degree as EducationDegreeType,
	Education,
	EducationLevel as EducationLevelType,
} from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import { SectionEdit } from '@pages/employee/Profile/Partials/SectionEdit'
import { useUpdateSection } from '@pages/employee/Profile/providers/UserProfileProvider'
import { sortEducation } from '@pages/employee/Profile/utils'

import { EducationDegree, EducationLevel } from '.'

import {
	EditContentWrapper,
	selectField,
	switchField,
} from '@pages/employee/Profile/Profile.Style'

interface Props {
	close: () => void
	education: Education[]
	editingIndex?: number | null
	preventSendData?: boolean
}

const newEducation: Education = {
	level: 'primary',
	schoolName: {
		title: '',
		value: null,
	},
	stillLearning: false,
	studiesMajor: {
		title: '',
		value: null,
	},
	profession: {
		title: '',
		value: null,
	},
	degree: '' as Degree,
}

const EducationEdit = ({
	close,
	education,
	editingIndex,
	preventSendData,
}: Props) => {
	const [ item, setItem ] = useState<Education>({
		...(education[editingIndex] ?? newEducation),
	})
	const updateSection = useUpdateSection()
	const [ levelError, setLevelError ] = useState<string>('')
	const [ degreeError, setDegreeError ] = useState<string>('')

	const getActualCompletionScore = useGetActualCompletionScore()

	const isEditing = typeof editingIndex === 'number'
	const isPrimaryLevel = item.level === 'primary'
	const isSecondaryLevel = item.level === 'secondary'
	const isHigher = item.level === 'higher'
	const isSectorial =
		item.level === 'basic_sectoral' ||
		item.level === 'basic_vocational' ||
		item.level === 'secondary_sectoral'
	const isStillLearning = item.stillLearning

	const shouldDisplayDegree = !(
		isStillLearning ||
		isPrimaryLevel ||
		isSecondaryLevel
	)

	const handleSave = async () => {
		let newEducation: Education[]

		const newItem: Education = {
			level: item.level,
			schoolName: isPrimaryLevel
				? null
				: item.schoolName?.title
				  ? item.schoolName
				  : null,
			studiesMajor: isPrimaryLevel
				? null
				: item.studiesMajor?.title
				  ? item.studiesMajor
				  : null,
			stillLearning: isPrimaryLevel ? false : item.stillLearning,
			profession:
				isSectorial && !isStillLearning
					? item.profession?.title
						? item.profession
						: null
					: null,
			degree:
				!isStillLearning && isHigher
					? item.degree
						? item.degree
						: null
					: null,
		}

		if (!item.level) return setLevelError('Pole jest wymagane')
		if (!item.degree && isHigher && !isStillLearning) return setDegreeError('Pole jest wymagane')

		if (isEditing) {
			newEducation = education.map((currentEducationItem, index) =>
				editingIndex === index ? newItem : currentEducationItem,
			)
		} else {
			newEducation = [ ...education, newItem ]
		}

		newEducation = sortEducation(newEducation)

		if (preventSendData) {
			updateSection('education', newEducation)
			close()
		} else {
			try {
				const completionScore = getActualCompletionScore('education', newEducation)
				await updateProfile('education', newEducation, completionScore)
				updateSection('education', newEducation)
				close()
			} catch (error) {
				console.error(error)
			}
		}
	}

	const handleRemove = async () => {
		const newEducation = education.filter((_, index) => index !== editingIndex)

		try {
			if (!preventSendData) {
				const completionScore = getActualCompletionScore('education', newEducation)
				await updateProfile('education', newEducation, completionScore)
			}
			updateSection('education', newEducation)
			close()
		} catch (error) {
			console.error(error)
		}
	}

	const handleSetProperty = <T extends keyof Education>(
		key: T,
		value: Education[T],
	) => {
		setItem(prev => ({
			...prev,
			[key]: value,
		}))
	}

	const renderOptions = (obj: unknown) => {
		if (typeof obj !== 'object') return

		return Object.keys(obj).map(key => (
			<MenuItem key={ key } value={ key }>
				{ obj[key] }
			</MenuItem>
		))
	}

	return (
		<SectionEdit
			header={ `${ isEditing ? 'Edytuj' : 'Dodaj' } wykształcenie` }
			closeFn={ close }
			saveFn={ handleSave }
			removeFn={ isEditing ? handleRemove : null }
		>
			<EditContentWrapper>
				<FormControl fullWidth className={ selectField }>
					<InputLabel id='education-level-label'>Wybierz poziom</InputLabel>
					<Select
						labelId='education-level-label'
						defaultValue={ 'primary' }
						value={ item.level }
						error={ !!levelError }
						onChange={ ({ target }) => {
							setLevelError('')
							handleSetProperty('level', target.value as EducationLevelType)
						} }
						label='Wybierz poziom'
						required
					>
						{ renderOptions(EducationLevel) }
					</Select>
					<FormHelperText error>{ levelError }</FormHelperText>
				</FormControl>
				{ !isPrimaryLevel && (
					<>
						<AutocompleteField
							error={ '' }
							type='schoolName'
							label='Nazwa uczelni/szkoły'
							dataValue={
								item.schoolName || {
									title: '',
									value: null,
								}
							}
							handleChange={ (value: BasicType) =>
								handleSetProperty('schoolName', value)
							}
						/>

						<AutocompleteField
							error={ '' }
							type='studiesMajor'
							label='Kierunek'
							dataValue={
								item.studiesMajor || {
									title: '',
									value: null,
								}
							}
							handleChange={ (value: BasicType) =>
								handleSetProperty('studiesMajor', value)
							}
						/>
						<FormControlLabel
							className={ switchField }
							control={
								<Switch
									checked={ item.stillLearning }
									onChange={ ({ target }) =>
										handleSetProperty('stillLearning', target.checked)
									}
								/>
							}
							label='Nadal się uczę'
						/>
					</>
				) }
				{ shouldDisplayDegree && (
					<>
						{ isSectorial ? (
							<AutocompleteField
								error={ '' }
								type='profession'
								label='Uzyskany zawód'
								dataValue={
									item.profession || {
										title: '',
										value: null,
									}
								}
								handleChange={ (value: BasicType) =>
									handleSetProperty('profession', value)
								}
							/>
						) : (
							<FormControl fullWidth className={ selectField }>
								<InputLabel id='education-degree-label'>
									Uzyskany tytuł
								</InputLabel>
								<Select
									value={ item.degree }
									labelId='education-degree-label'
									label='Wybierz tytuł'
									error={ !!degreeError }
									required
									onChange={ ({ target }) => {
										setDegreeError('')
										handleSetProperty(
											'degree',
											target.value as EducationDegreeType,
										)
									} }
								>
									{ renderOptions(EducationDegree) }
								</Select>
								<FormHelperText error>{ degreeError }</FormHelperText>
							</FormControl>
						) }
					</>
				) }
			</EditContentWrapper>
		</SectionEdit>
	)
}

export default EducationEdit
