import moment from 'moment'

import {
	BasicType,
	Course,
	Degree,
	EducationLevel,
	Language,
	LanguageLevel,
	LookingForJob,
	ProfileValidatable,
} from '../interfaces'

export const isEducationLevel = (value: unknown): value is EducationLevel =>
	typeof value === 'string' &&
	[
		'primary',
		'basic_vocational',
		'basic_sectoral',
		'secondary_sectoral',
		'secondary',
		'higher',
	].includes(value)

export const isLanguageLevel = (value: unknown): value is LanguageLevel =>
	typeof value === 'string' &&
	[
		'a1_beginner',
		'a2_elementary',
		'b1_intermediate',
		'b2_upper_intermediate',
		'c1_advanced',
		'c2_proficient',
	].includes(value)

export const isEducationDegree = (value: unknown): value is Degree =>
	typeof value === 'string' &&
	[
		'bachelors',
		'engineer',
		'masters',
		'master_of_engineering',
		'doctor',
		'habilitated_doctor',
		'professor',
	].includes(value)

export const isArrayOfBasicData = (data: unknown[]): data is BasicType[] =>
	data.every(item => typeof item === 'object' && 'value' in item)

export const isArrayOfLanguages = (data: unknown[]): data is Language[] =>
	data.every(item => typeof item === 'object' && 'level' in item)

export const isArrayOfCourses = (data: unknown[]): data is Course[] =>
	data.every(item => typeof item === 'object' && 'endDate' in item)

export const isArrayOfStrings = (data: unknown[]): data is string[] =>
	data.every(item => typeof item === 'string')

export const validateProfileValidatable = (
	key: string,
): key is ProfileValidatable => {
	return [
		'basicData',
		'education',
		'preferences',
		'professionalExperience',
		'links',
		'courses',
		'summary',
		'interests',
		'licences',
		'skills',
		'languages',
	].includes(key)
}

export const validateProfessionalStatus = (
	value: string,
): value is LookingForJob => [ 'yes', 'maybe', 'no' ].includes(value)

export const validateDate = (value: string) => {
	if (!value) return 

	return moment(value).isValid() ? '' : 'Niepoprawna data'
}

export const validateYearWithMonth = (value: string) => {
	if(!value) return ''

	return /^\d{4}-\d{2}$/.test(value) ? '' : 'Niepoprawna data'
}

export const validatePresence = (value: string): string =>
	value ? '' : 'Wartość jest wymagana'

export const validateNumber = (value: string): string => {
	if (!value) return ''
	return /^\d*$/.test(value) || !isNaN(+value) ? '' : 'Niepoprawna wartość'
}

export const validateMinLength = (value: string, minLength: number): string => {
	if (!value?.length) return
	return value === null || value.length === 0 || value.length >= minLength
		? ''
		: `Wymagane minimum ${ minLength } ${ minLength > 4 ? 'znaków' : 'znaki' }`
}

export const validateMaxLength = (value: string, maxLength: number): string => {
	if (!value?.length) return
	return value === null || value.length <= maxLength
		? ''
		: `Wymagane maximum ${ maxLength } ${ maxLength > 4 ? 'znaków' : 'znaki' }`
}

export const validateMinValue = (value: number, minValue: number): string =>
	!value
		? ''
		: value >= minValue
		  ? ''
		  : `Minimalna wartość dla pola to ${ minValue }`

export const validateMaxValue = (value: number, maxValue: number): string =>
	!value
		? ''
		: value <= maxValue
		  ? ''
		  : `Maksymalna wartość dla pola to ${ maxValue }`

export const validateUrl = (value: string): string => {
	if (!value) return ''

	try {
		const checkUrl = new URL(value)
		if (checkUrl.protocol !== 'https:' || !checkUrl.hostname.includes('.'))
			throw new Error()
	} catch (err) {
		return 'Adres url ma niepoprawny format'
	}
	return ''
}

export const validatePhoneFormat = (value: string): string => {
	if (!value) return

	return /^\+?[0-9]+$/.test(value)
		? ''
		: 'Numer telefonu zawiera niepoprawne znaki'
}

export const validatePhoneMinLength = (value: string): string => {
	if (!value) return
	const phoneNumber = value.includes('+') ? value.replace('+', '') : value

	return validateMinLength(phoneNumber, 9)
}

export const validatePhoneMaxLength = (value: string): string => {
	if (!value) return
	const phoneNumber = value.includes('+') ? value.replace('+', '') : value

	return validateMaxLength(phoneNumber, 12)
}

export const validateEnoughYears = (
	value: string,
	yearsAmount: number,
): string => {
	if (!value) return
	const date = moment(value)

	return moment().year() - yearsAmount >= date.year()
		? ''
		: `Data musi być późniejsza niż ${ yearsAmount } lat temu`
}

export const validateDateNotHigherThanCurrent = (value: string): string => {
	if (!value) return
	const date = moment(value)

	return date.isSameOrBefore(moment()) ? '' : 'Data jest większa niż obecna'
}

export const validateTooMuchYears = (
	value: string,
	yearsAmount: number,
): string => {
	if (!value) return
	const date = moment(value)

	return date.year() >= moment().year() - yearsAmount
		? ''
		: `Data jest poźniejsza niż ${ yearsAmount } lat`
}

export const validateStartDateExists = (
	value: string,
	startDate: string,
	currentlyWorking: boolean,
) => {
	return startDate && !currentlyWorking && !value ? 'Wartość jest wymagana' : ''
}

export const validateStartDateLowerThanEndDate = (
	value: string,
	endDate?: string,
): string => {
	if (!value || !endDate) return
	const start = moment(value)
	const end = moment(endDate)

	return end.isBefore(moment(start))
		? 'Data startowa jest większa niż końcowa'
		: ''
}

export const validateExperienceYears = (value: number): string => {
	return validateMinValue(value, 0)
}

export const validateRadiusRequired = (value: number): string => {
	return getValidationResult([
		validateMinValue(value, 0),
		validateMaxValue(value, 500),
		typeof value !== 'number' ? 'Wartość jest wymagana' : '',
	])
}

export const validateRadius = (value: number): string => {
	return getValidationResult([
		validateMinValue(value, 0),
		validateMaxValue(value, 500),
	])
}

export const validateExpectedSalary = (value: number): string => {
	return getValidationResult([
		validateMaxValue(value, 100000),
		validateNumber(value?.toString()),
	])
}

export const validateCvExperienceYears = (value: string): string => {
	return getValidationResult([
		validateMaxValue(+value, 99),
		validateMinValue(+value, 0),
		validateNumber(value?.toString()),
	])
}

export const validateBasicText = (value: string): string => {
	return getValidationResult([
		validateMinLength(value, 2),
		validatePresence(value),
	])
}

export const validateFirstOrLastName = (value: string): string => {
	return getValidationResult([
		validateMinLength(value, 2),
		validatePresence(value),
	])
}

export const validatePhoneNumber = (value: string): string => {
	return getValidationResult([
		validatePhoneMinLength(value),
		validatePhoneMaxLength(value),
		validatePhoneFormat(value),
		validatePresence(value),
	])
}

export const validateLink = (value: string): string => {
	return getValidationResult([
		validateUrl(value),
		validateMinLength(value, 3),
		validatePresence(value),
	])
}

export const validate99Years = (value: string): string =>
	getValidationResult([ validateTooMuchYears(value, 99), validateDate(value) ])

export const validateBirthDate = (value: string): string => {
	return getValidationResult([
		validateEnoughYears(value, 15),
		validate99Years(value),
		validateDate(value),
	])
}

export const validateCourseDate = (value: string): string => {
	return getValidationResult([ validate99Years(value), validateDate(value) ])
}

export const validateExperienceStartDate = (
	value: string,
	currentState,
): string => {
	return getValidationResult([
		validateStartDateLowerThanEndDate(value, currentState.endDate),
		validateDateNotHigherThanCurrent(value),
		validate99Years(value),
		validateDate(value),
	])
}

export const validateExperienceEndDate = (
	value: string,
	currentState,
): string => {
	return getValidationResult([
		validateStartDateExists(
			value,
			currentState.startDate,
			currentState.currentlyWorking,
		),
		validate99Years(value),
		validateDate(value),
	])
}

export const validateSummary = (value: string) => validateMaxLength(value, 4000)

const getValidationResult = (results: string[]) => {
	const filteredResults = results.filter(error => error)

	if (filteredResults.length === 0) return ''

	return filteredResults[filteredResults.length - 1]
}
