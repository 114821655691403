import { cloneDeep } from 'lodash'
import swal from 'sweetalert2'
import uuid from 'uuid/v4'

import Fetch from '@services/Fetch'

import {
	isArrayOfBasicData,
	isArrayOfCourses,
	isArrayOfLanguages,
	isArrayOfStrings,
} from './validations/validators'
import { SECTION_PERCENTAGE } from './constants'
import {
	BasicDataForEdit,
	BasicType,
	Course,
	Education,
	Language,
	Preferences,
	ProfessionalExperience,
	ProfileEditable,
	ProfilePointable,
	ProfileTwigData,
} from './interfaces'

const getPointsForSection = <T extends keyof ProfilePointable>(
	section: T,
	payload: ProfilePointable[T],
): number => {
	switch (section) {
	case 'basicData': {
		const fields = payload as BasicDataForEdit
		return fields.firstName && fields.lastName
			? SECTION_PERCENTAGE[section]
			: 0
	}
	case 'preferences': {
		const fields = payload as Preferences

		let fieldCorrect = false

		if (
			fields.preferredPosition?.position?.title ||
				fields.contractType?.includes('b2b') ||
				fields.preferredLocation?.location?.title ||
				fields.preferredLocation?.workModel?.includes('remote')
		) {
			fieldCorrect = true
		}

		return fieldCorrect ? SECTION_PERCENTAGE[section] : 0
	}
	case 'summary': {
		const data = payload as string
		return data ? SECTION_PERCENTAGE[section] : 0
	}
	case 'professionalExperience':
	case 'education':
	case 'languages':
	case 'skills':
	case 'courses':
	case 'licences':
	case 'interests':
	case 'links': {
		const data = payload as Array<ProfilePointable[T]>
		return data.length > 0 ? SECTION_PERCENTAGE[section] : 0
	}

	default:
		console.error('Cannot determine percentage for given section')
		return 0
	}
}

export const getTotalPoints = (userProfile: ProfilePointable): number => {
	let points = 0

	for (const key in userProfile) {
		points += getPointsForSection(
			key as keyof ProfilePointable,
			userProfile[key],
		)
	}

	return points
}

export const twigToProfileEditable = (
	data: ProfileTwigData,
): ProfileEditable => {
	delete data.files

	const profileEditable = {
		...data.profile,
		email: data.email,
		agencyConsent: data.agencyConsent,
		photo: data.profile.basicData.photo?.url ?? null,
		education: sortEducation(data.profile.education),
		professionalExperience: sortExperience(data.profile.professionalExperience),
	}

	delete profileEditable.basicData.photo

	return profileEditable
}

export const fileToBase64 = (file: File): Promise<string> => {
	return new Promise(resolve => {
		const reader = new FileReader()
		reader.addEventListener(
			'load',
			() => resolve(reader.result.toString()),
			false,
		)
		reader.readAsDataURL(file)
	})
}

export const downloadCv = async () => {
	try {
		const response = await Fetch('GET', '/profile-download')

		if (!response.ok) {
			throw new Error()
		}

		const data = await response.json()

		const downloadAnchor = document.createElement('a')

		downloadAnchor.href = data.downloadLink
		downloadAnchor.download = data.name

		document.body.appendChild(downloadAnchor)

		downloadAnchor.click()

		document.body.removeChild(downloadAnchor)
	} catch (err) {
		swal({
			title: 'Wystąpił błąd',
			text: 'Nie udało się wygenerować CV',
			type: 'error',
		})
	}
}

export const getRandomID = (): string => uuid().toString()

export const getRandomBetween = (min: number, max: number) =>
	Math.floor(Math.random() * (max - min + 1) + min)

export function filterEmptyObjects(values: Language[]): Language[]
export function filterEmptyObjects(values: BasicType[]): BasicType[]
export function filterEmptyObjects(values: Course[]): Course[]
export function filterEmptyObjects(values: string[]): string[]
export function filterEmptyObjects(
	values: BasicType[] | Language[] | Course[] | string[],
) {
	if (isArrayOfLanguages(values))
		return values.filter(language => language.name.title)

	if (isArrayOfBasicData(values))
		return values.filter(basicType => basicType.title)

	if (isArrayOfCourses(values)) return values.filter(course => course.name)

	if (isArrayOfStrings(values)) return values.filter(value => value)

	return values
}

export const sortExperience = (data: ProfessionalExperience[]) => {
	return cloneDeep(data).sort((a, b) => {
		const dateAStart = new Date(a.startDate)
		const dateBStart = new Date(b.startDate)

		if (dateAStart > dateBStart) return -1
		if (dateAStart < dateBStart) return 1

		if (a.currentlyWorking && !b.currentlyWorking) return -1
		if (!a.currentlyWorking && b.currentlyWorking) return 1

		const dateAEnd = a.endDate ? new Date(a.endDate) : null
		const dateBEnd = b.endDate ? new Date(b.endDate) : null

		if ((!dateAEnd && dateBEnd) || dateAEnd > dateBEnd) return -1
		if ((dateAEnd && !dateBEnd) || dateAEnd < dateBEnd) return 1

		return 0
	})
}

export const sortEducation = (data: Education[]) => {
	const educationLevelOrder = [
		'higher',
		'secondary',
		'secondary_sectoral',
		'basic_sectoral',
		'basic_vocational',
		'primary',
	]
	const degreeOrder = [
		'professor',
		'habilitated_doctor',
		'doctor',
		'master_of_engineering',
		'masters',
		'engineer',
		'bachelors',
	]

	return cloneDeep(data).sort((a, b) => {
		const levelA = educationLevelOrder.indexOf(a.level)
		const levelB = educationLevelOrder.indexOf(b.level)

		if (levelA > levelB) return 1
		if (levelA < levelB) return -1

		const degreeA = a.degree
			? degreeOrder.indexOf(a.degree)
			: degreeOrder.length
		const degreeB = b.degree
			? degreeOrder.indexOf(b.degree)
			: degreeOrder.length

		if (degreeA > degreeB) return 1
		if (degreeA < degreeB) return -1

		const hasProfessionA = a.profession ? 1 : 0
		const hasProfessionB = b.profession ? 1 : 0

		if (hasProfessionA > hasProfessionB) return -1
		if (hasProfessionA < hasProfessionB) return 1

		return 0
	})
}

export function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180
}

export const createImage = (url): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const image = new Image()
		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', error => reject(error))
		image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
		image.src = url
	})

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
	const rotRad = getRadianAngle(rotation)

	return {
		width:
			Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
		height:
			Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
	}
}

export async function getCroppedImg(
	imageSrc,
	pixelCrop,
	rotation = 0,
	flip = { horizontal: false, vertical: false },
) {
	const image: HTMLImageElement = await createImage(imageSrc)
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')

	if (!ctx) {
		return null
	}

	const rotRad = getRadianAngle(rotation)

	// calculate bounding box of the rotated image
	const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
		image.width,
		image.height,
		rotation,
	)

	// set canvas size to match the bounding box
	canvas.width = bBoxWidth
	canvas.height = bBoxHeight

	// translate canvas context to a central location to allow rotating and flipping around the center
	ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
	ctx.rotate(rotRad)
	ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
	ctx.translate(-image.width / 2, -image.height / 2)

	// draw rotated image
	ctx.drawImage(image, 0, 0)

	const croppedCanvas = document.createElement('canvas')

	const croppedCtx = croppedCanvas.getContext('2d')

	if (!croppedCtx) {
		return null
	}

	if (croppedCanvas.width && croppedCanvas.height) {
		// Set the size of the cropped canvas
		croppedCanvas.width = pixelCrop.width
		croppedCanvas.height = pixelCrop.height

		// Draw the cropped image onto the new canvas
		croppedCtx.drawImage(
			canvas,
			pixelCrop.x,
			pixelCrop.y,
			pixelCrop.width,
			pixelCrop.height,
			0,
			0,
			pixelCrop.width,
			pixelCrop.height,
		)

		// As Base64 string
		return croppedCanvas.toDataURL('image/jpeg')
	}
}
