import Styled, { css } from 'react-emotion'

import { Alpha, Black, Blue, Gray, White } from '@styles/Color'
import { DisplayFlex, Flex } from '@styles/Flex'
import {
	AlignSelf,
	DisplayGrid,
	GridColumn,
	GridRow,
	GridTemplateColumns,
	GridTemplateRows,
	JustifySelf,
} from '@styles/Grid'
import { MediaQuery } from '@styles/MediaQueries'

/*
 * Classes
 */

export const closeButton = css({
	...GridColumn(2),
	...GridRow(1),
	width: 20,
	height: 20,
})

/*
 * Components
 */

export const Backdrop = Styled('div')<{ backdropColor?: boolean, visible?: boolean }>(
	({ backdropColor, visible }) => ({
		alignContent: 'center',
		background: Alpha(Black, 0.2),
		bottom: 0,
		...DisplayGrid(),
		...GridTemplateColumns('1fr'),
		...GridTemplateRows('1fr'),
		left: 0,
		overflow: 'hidden',
		position: 'fixed',
		right: 0,
		top: visible ? 0 : 9999,
		zIndex: 1000,
	}),
)

export const Container = Styled('div')<{
	notFullScreen?: boolean
	bgColor?: string
	fullScreenMedium?: boolean
	smallPadding?: boolean
}>(({ notFullScreen, bgColor, fullScreenMedium, smallPadding }) => ({
	...AlignSelf('center'),
	background: bgColor || White,
	borderRadius: 22,
	boxShadow: '0 0 21px 0 #9E9E9E',
	boxSizing: 'border-box',
	...DisplayGrid(),
	...GridTemplateRows('auto 1fr auto'),
	...GridTemplateColumns('1fr auto'),
	height: notFullScreen ? 'auto' : '100vh',
	...JustifySelf('center'),
	maxHeight: notFullScreen ? 'calc(100vh - 30px)' : null,
	maxWidth: notFullScreen ? 'calc(100vw - 30px)' : null,
	minWidth: notFullScreen ? 290 : null,
	overflow: 'auto',
	padding: smallPadding ? 10 : 20,
	position: notFullScreen ? 'relative' : 'fixed',
	width: notFullScreen ? ['auto', 'fit-content'] : '100vw',

	[MediaQuery.Medium]: {
		height: fullScreenMedium ? '100vh' : ['auto', 'fit-content'],
		maxHeight: fullScreenMedium ? '100vh' : 'calc(100vh - 30px)',
		maxWidth: fullScreenMedium ? '100vw' : 'calc(100vw - 30px)',
		minWidth: 320,
		position: fullScreenMedium ? 'fixed' : 'relative',
		padding: 20,
		width: fullScreenMedium ? '100vw' : ['auto', 'fit-content'],
	},

	[MediaQuery.Large]: {
		height: ['auto', 'fit-content'],
		maxHeight: 'calc(100vh - 30px)',
		maxWidth: 'calc(100vw - 30px)',
		position: 'relative',
		width: ['auto', 'fit-content'],
	},
}))

export const Content = Styled('div')({
	...GridColumn(1, 2),
	...GridRow(2),
})

export const ModalFooter = Styled('div')({
	...GridColumn(1, 2),
	...GridRow(3),
})

export const ModalHeader = Styled('div')({
	...AlignSelf('center', true),
	boxSizing: 'border-box',
	...GridColumn(1),
	...GridRow(1),
	paddingRight: 20,
})
