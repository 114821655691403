import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import {
	BasicType,
	LookingForJob,
	PositionLevel, Preferences, PreferredPosition,
} from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import NumericInput from '@pages/employee/Profile/Partials/NumericInput'
import RadioYesNo from '@pages/employee/Profile/Partials/RadioYesNo'
import { PositionLevel as PositionLevelEnum } from '@pages/employee/Profile/Partials/Sections/PreferencesSection'
import { Gray, Green, Yellow } from '@styles/Color'

import {
	lookingForJobSelect, SectionBlock,
	SectionTitle,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'
import {
	StatusBullet,
	statusSelect,
} from '@pages/employee/Profile/Profile.Style'

export type Stage3Ref = {
	validateAll: () => boolean
	getCurrentData: () => {
		preferences: Preferences,
		lookingForJob: LookingForJob }
}

export declare type Stage3Field = 'lookingForJob' | keyof PreferredPosition

interface Stage3Props {
	updateLookingForJob: (value: LookingForJob) => void
	updatePosition: (value: Preferences) => void
	loading: string[]
	lookingForJob: LookingForJob | null
	preferences: Preferences
}

const Stage3 = forwardRef(function Stage3(props: Stage3Props, ref) {
	const [ edited, setEdited ] = useState<Array<Stage3Field>>([])
	
	const lookingForJobData = useEditSection('lookingForJob', props.lookingForJob)
	const preferences =
	useEditSection('preferences', props.preferences)
	
	const experienceInputRef = useRef<HTMLDivElement>()

	useEffect(() => {
		const newEdited = []

		if (props.lookingForJob) {
			newEdited.push('lookingForJob')
		}
		for (const [ key, value ] of Object.entries(props.preferences?.preferredPosition)) {
			if (value) {
				newEdited.push(key)
			}
		}
		setEdited(newEdited)
	}, [])


	useImperativeHandle(ref, () => {
		return {
			validateAll(): boolean {
				return lookingForJobData.validate() &&
					preferences.validate([ 'position', 'level', 'hasExperience', 'experienceYears' ])
			},
			getCurrentData(): { preferences: Preferences, lookingForJob: LookingForJob } {
				return {
					lookingForJob: lookingForJobData.currentValue,
					preferences: preferences.currentValue
				}
			}
		}
	})

	useEffect(() => {
		sendUpdate()
	}, [
		preferences.currentValue.preferredPosition.hasExperience,
		preferences.currentValue.preferredPosition.experienceYears
	])

	useEffect(() => {
		if(!experienceInputRef.current) return
		
		experienceInputRef.current.scrollIntoView({
			behavior: 'smooth'
		})
	}, [preferences.currentValue.preferredPosition.hasExperience])

	const sendUpdate = () => {
		if (edited.length === 0 || !preferences.validate(edited)) {
			return
		}
		setTimeout(() => {
			props.updatePosition(preferences.currentValue)
		}, 100)
	}

	const editChange = (fieldName: Stage3Field) => {
		if (!edited.includes(fieldName)) {
			setEdited(prev => ([ ...prev, fieldName ]))
		}
	}

	const changeHandler = (fieldName: Stage3Field, value: string | BasicType | boolean | number) => {
		editChange(fieldName)
		if (fieldName === 'hasExperience' && !value && preferences.currentValue?.preferredPosition?.experienceYears !== null) {
			preferences.handleChange(null, '[preferredPosition][experienceYears]')
		}

		preferences.handleChange(value, `[preferredPosition][${ fieldName }]`)
	}

	const blurHandler = (fieldName: Stage3Field) => {
		editChange(fieldName)
		sendUpdate()
	}

	const currentTitle = preferences.currentValue.preferredPosition?.position?.title

	return (
		<div>
			<SectionTitle>Określ swój status poszukiwania pracy</SectionTitle>
			<SectionBlock>
				<FormControl className={ statusSelect } fullWidth>
					<Select
						className={ lookingForJobSelect(props.lookingForJob) }
						value={ lookingForJobData.currentValue || '' }
						onChange={ ({ target }) => {
							lookingForJobData.handleChange(target.value)
							props.updateLookingForJob(target.value as LookingForJob)
						} }
						displayEmpty
						fullWidth
					>
						<MenuItem style={{ display: 'none' }} value=''>
							Wybierz status zawodowy
						</MenuItem>
						<MenuItem value="yes">
							<StatusBullet color={ Green[600] } />
							Aktywnie poszukuję pracy
						</MenuItem>
						<MenuItem value="maybe">
							<StatusBullet color={ Yellow[800] } />
							Chętnie poznam nowe możliwości zawodowe
						</MenuItem>
						<MenuItem value="no">
							<StatusBullet color={ Gray[600] } />
							Nie poszukuję nowej pracy
						</MenuItem>
					</Select>
				</FormControl>
			</SectionBlock>
			<SectionTitle>Na jakim stanowisku szukasz pracy?</SectionTitle>
			<SectionBlock>
				<AutocompleteField
					error={ preferences.errors?.preferredPosition?.position?.title ?? '' }
					type="position"
					label="Tytuł stanowiska"
					required
					dataValue={ preferences.currentValue?.preferredPosition?.position }
					handleChange={ (value: BasicType) => {
						if (value?.title !== currentTitle) {
							changeHandler('position', value)
						}
					} }
					handleBlur={ () => blurHandler('position') }
				/>
			</SectionBlock>
			<SectionTitle>Określ poziom stanowiska</SectionTitle>
			<SectionBlock style={{ marginBottom: 40 }}>
				<FormControl fullWidth>
					<InputLabel id="level-select-label">Wybierz poziom stanowiska</InputLabel>
					<Select
						labelId="level-select-label"
						value={ preferences.currentValue?.preferredPosition?.level || '' }
						onChange={ ({ target }) => {
							if (target.value !== preferences.currentValue.preferredPosition.level) {
								changeHandler('level', target.value as PositionLevel) }
						} }
						onBlur={ () => blurHandler('level') }
						label="Wybierz poziom stanowiska"
						disabled={ currentTitle?.length === 0 }
					>
						{ Object.keys(PositionLevelEnum).map((key) => (
							<MenuItem key={ key } value={ key }>
								{ PositionLevelEnum[key] }
							</MenuItem>
						)) }
					</Select>
				</FormControl>
			</SectionBlock>
			<SectionTitle>Czy masz doświadczenie na stanowisku, na którym szukasz pracy?</SectionTitle>
			<SectionBlock>
				<RadioYesNo
					value={ preferences.currentValue?.preferredPosition?.hasExperience ?? null }
					onValueChange={ (value) => changeHandler('hasExperience', value) }
					disabled={ props.loading.indexOf('hasExperience') > -1 || currentTitle?.length === 0 }
					withoutDefault
				/>
			</SectionBlock>
			<div>
				{ preferences.currentValue?.preferredPosition?.hasExperience && (
					<>
						<SectionBlock>Ile lat doświadczenia?</SectionBlock>
						<SectionBlock style={{ marginBottom: 20 }} innerRef={ experienceInputRef }>
							<NumericInput
								onValueChange={ (value) => changeHandler('experienceYears', value) }
								value={ preferences.currentValue?.preferredPosition?.experienceYears }
								disabled={ props.loading.indexOf('experienceYears') > -1 }
								error={ preferences.errors?.preferredPosition?.experienceYears ?? '' }
							/>
						</SectionBlock>
					</>
				) }
			</div>
		</div>
	)
})
Stage3.displayName = 'Stage3'


export default Stage3
