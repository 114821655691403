import React, { useContext, useEffect, useRef, useState } from 'react'
import _, { cloneDeep } from 'lodash'
import ReactDOM from 'react-dom'
import swal from 'sweetalert2'

import {
	AgencyConsent,
	BasicDataForEdit,
	BasicType,
	CreatorBasicData,
	CreatorEditable,
	Education,
	Language,
	LookingForJob,
	Preferences,
	ProfilePointable,
	ProfileTwigData,
} from '@pages/employee/Profile/interfaces'
import {
	confirmCreatorAbort,
	confirmParsedDataAbort,
	confirmParseProcessAbort, confirmParserAbort,
} from '@pages/employee/Profile/Partials/Creator/confimations'
import {
	changePage,
	getEmptyPreferences,
	getProfileData, parseCv, removeEmptyAutocomplete,
	removeFromLoading, sendAgencyConsent, sendData,
	uploadParsedData,
} from '@pages/employee/Profile/Partials/Creator/functions'
import ParsingAwait from '@pages/employee/Profile/Partials/Creator/Stages/ParsingAwait'
import ParsingError from '@pages/employee/Profile/Partials/Creator/Stages/ParsingError'
import ParsingStart from '@pages/employee/Profile/Partials/Creator/Stages/ParsingStart'
import ParsingSuccess from '@pages/employee/Profile/Partials/Creator/Stages/ParsingSuccess'
import ParsingVerification from '@pages/employee/Profile/Partials/Creator/Stages/ParsingVerification'
import Stage1, { Stage1Data, Stage1Ref } from '@pages/employee/Profile/Partials/Creator/Stages/Stage1'
import Stage2, { Stage2Ref } from '@pages/employee/Profile/Partials/Creator/Stages/Stage2'
import Stage3, { Stage3Ref } from '@pages/employee/Profile/Partials/Creator/Stages/Stage3'
import Stage4, { Stage4Ref } from '@pages/employee/Profile/Partials/Creator/Stages/Stage4'
import Stage5, { Stage5Ref } from '@pages/employee/Profile/Partials/Creator/Stages/Stage5'
import Stage6, { Stage6Ref } from '@pages/employee/Profile/Partials/Creator/Stages/Stage6'
import Stage7, { Stage7Ref } from '@pages/employee/Profile/Partials/Creator/Stages/Stage7'
import Stage8, { Stage8Ref } from '@pages/employee/Profile/Partials/Creator/Stages/Stage8'
import { ModalRef, ProfileModal } from '@pages/employee/Profile/Partials/PopupModal'
import { UserProfileContext, UserProfileProvider } from '@pages/employee/Profile/providers/UserProfileProvider'
import { getTotalPoints, sortEducation, sortExperience } from '@pages/employee/Profile/utils'

import {
	CreatorHeader,
	Logo, ParsingStatus, ParsingStatusBlock,
	ProgressBlock, PulsingIcon, Stage1Image,
	StageLinearProgress,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'

let root = document.getElementById('creator-modal-root')
if (!root) {
	root = document.createElement('div')
	root.id = 'creator-modal-root'
	document.body.appendChild(root)
}

export declare type LoadingNames = keyof CreatorEditable

export declare type LoadingChangeFn = (
	start: boolean,
	name: LoadingNames | 'file',
	fileParsing?: boolean
) => void

export declare type StagesRefs = Stage1Ref | Stage2Ref | Stage3Ref | Stage4Ref | Stage5Ref | Stage6Ref | Stage7Ref | Stage8Ref

const loadProfileData = async() => await getProfileData()
const confirmClose = async(onlyParser?: boolean) => {
	const result = await swal(onlyParser ? confirmParserAbort : confirmCreatorAbort)

	if (result?.dismiss as unknown as string === 'cancel') {
		ReactDOM.unmountComponentAtNode(root)
	} else {
		return false
	}
}

const Creator = ({ showOnlyParser }: { showOnlyParser?: boolean }) => {
	const modalRef: React.RefObject<ModalRef> = useRef()
	const stageRef: React.RefObject<HTMLInputElement> = useRef()
	const nextStateRef: React.RefObject<HTMLInputElement> = useRef()
	const contentRef: React.RefObject<HTMLDivElement> = useRef()

	const stagesRefs = {
		stage1Ref: useRef<Stage1Ref>(),
		stage2Ref: useRef<Stage2Ref>(),
		stage3Ref: useRef<Stage3Ref>(),
		stage4Ref: useRef<Stage4Ref>(),
		stage5Ref: useRef<Stage5Ref>(),
		stage6Ref: useRef<Stage6Ref>(),
		stage7Ref: useRef<Stage7Ref>(),
		stage8Ref: useRef<Stage8Ref>()
	}

	const [ stage, setStage ] = useState<number>(showOnlyParser ? 2 : 1)
	const [ maxStages, setMaxStages ] = useState<number>(8)

	const [ creatorLoading, setCreatorLoading ] = useState<boolean>(true)

	const [ loading, setLoading ] = useState<string[]>([])

	const [ cvParsing, setCvParsing ] = useState<boolean>(false)
	const [ cvParsingResult, setCvParsingResult ] = useState<'success' | 'error'>()

	const [ showAgencyConsent, setShowAgencyConsent ] = useState<boolean>(false)

	const [ nextClicked, setNextClicked ] = useState<boolean>(false)

	const { initializeSectionsData, sections, files, parsingServiceAvailable, updateSectionData} =
		useContext(UserProfileContext)

	useEffect(() => {
		loadProfileData().then((data) => {
			const {
				profile,
				email,
				agencyConsent,
				parsingServiceAvailable,
				files,
			} = data

			initializeSectionsData({
				agencyConsent,
				email,
				files,
				parsingServiceAvailable,
				profile,
			} as ProfileTwigData)

			setShowAgencyConsent(!agencyConsent.accepted && !showOnlyParser)

			updateSectionData('basicData', data.profile.basicData)
			updateSectionData('interests', data.profile.interests)
			updateSectionData('skills', data.profile.skills)
			updateSectionData('education', data.profile.education)
			updateSectionData('languages', data.profile.languages)
			updateSectionData('professionalExperience', data.profile.professionalExperience)
			if (data.profile.preferences.preferredLocation || data.profile.preferences.preferredPosition) {
				updateSectionData('preferences', data.profile.preferences)
			} else {
				updateSectionData('preferences', getEmptyPreferences())
			}

			updateSectionData('lookingForJob', data.profile.lookingForJob)
			updateSectionData('courses', data.profile.courses)
			updateSectionData('licences', data.profile.licences)
			updateSectionData('links', data.profile.links)
			updateSectionData('summary', data.profile.summary)
		})
		setCreatorLoading(false)
	}, [])

	useEffect(() => {
		if (stage === 5.3 && (cvParsing || cvParsingResult)) {
			setCvParsing(false)
			setCvParsingResult(undefined)
		}
	}, [stage])

	if (!sections || creatorLoading) {
		return null
	}

	const {
		basicData, courses, education, interests, languages, skills, summary,
		lookingForJob, licences, links, agencyConsent, professionalExperience, preferences,
	} = sections

	const loadingChange: LoadingChangeFn = (start, name, fileParsing?: boolean) => {
		if (start) {
			setLoading((oldData) => [ ...oldData, name ])
		} else {
			setLoading((oldData) => removeFromLoading(oldData, name))
			if (nextStateRef.current.value === 'true') {
				nextAction(true, fileParsing)
			}
		}
	}

	const updateBasicData = async (data: Stage1Data) => {
		updateSectionData('basicData', data as BasicDataForEdit)
		sendData({
			newValue: data as CreatorBasicData,
			refToMatch: stagesRefs.stage1Ref,
			loadingFn: loadingChange,
			name: 'basicData',
			removeNulls: true,
			sections,
		})
	}
	const updateAgencyConsent = async(value: AgencyConsent) => {
		updateSectionData('agencyConsent', value)
		loadingChange(true, 'agencyConsent')
		await sendAgencyConsent({ enabled: value.accepted }).then(() => loadingChange(false, 'agencyConsent'))
	}
	const updateLookingForJob = async(value: LookingForJob) => {
		updateSectionData('lookingForJob', value)

		sendData({
			newValue: value as LookingForJob,
			valueKeyToCheck: ['lookingForJob'],
			refToMatch: stagesRefs.stage3Ref,
			loadingFn: loadingChange,
			name: 'lookingForJob',
			sections,
		})
	}
	const updatePreferredPosition = async(newValue: Preferences) => {
		updateSectionData('preferences', newValue)
		sendData({
			newValue: newValue,
			valueKeyToCheck: ['preferences'],
			refToMatch: stagesRefs.stage3Ref,
			loadingFn: loadingChange,
			name: 'preferences',
			sections,
		})
	}
	const updateLocationPreferences = async(newValue: Preferences) => {
		updateSectionData('preferences', newValue)
		sendData({
			newValue: newValue,
			valueKeyToCheck: ['preferences'],
			refToMatch: stagesRefs.stage4Ref,
			loadingFn: loadingChange,
			name: 'preferences',
			sections,
		})
	}
	const updateWorkConditions = async(newValue: Preferences) => {
		updateSectionData('preferences', newValue)
		sendData({
			newValue: newValue,
			valueKeyToCheck: ['preferences'],
			refToMatch: stagesRefs.stage5Ref,
			loadingFn: loadingChange,
			name: 'preferences',
			sections,
		})
	}
	const updateEducation = (newValue: Education[]) => {
		const firstRecord: Education = newValue[0]

		if (firstRecord.level === 'primary') {
			firstRecord.degree = null
			firstRecord.studiesMajor = null
			firstRecord.schoolName = null
			firstRecord.profession = null
			firstRecord.stillLearning = false
		}
		updateSectionData('education', newValue)
		const clearedData = cloneDeep(newValue)
		clearedData[0] = removeEmptyAutocomplete(newValue[0])

		sendData({
			newValue: newValue,
			refToMatch: stagesRefs.stage6Ref,
			loadingFn: loadingChange,
			name: 'education',
			sections,
		})
	}
	const updateLanguages = (newValue: Language[]) => {
		updateSectionData('languages', newValue)
		sendData({
			newValue: newValue,
			refToMatch: stagesRefs.stage7Ref,
			loadingFn: loadingChange,
			name: 'languages',
			sections,
		})
	}
	const updateSkillsLicences = (name: 'skills' | 'licences', newValue: BasicType[]) => {
		updateSectionData(name, newValue)
		sendData({
			newValue: newValue,
			valueKeyToCheck: [name],
			refToMatch: stagesRefs.stage8Ref,
			loadingFn: loadingChange,
			name: name,
			sections,
		})

	}

	const getContent = () => {
		switch (stage) {
		case 1:
			return <Stage1
				ref={ stagesRefs.stage1Ref }
				loading={ loading }
				updateForm={ updateBasicData }
				basicData={ basicData }
			/>
		case 2:
			return <Stage2
				ref={ stagesRefs.stage2Ref }
				loading={ loading.includes('agencyConsent') }
				updateForm={ updateAgencyConsent }
				agencyConsent={ agencyConsent }
				showAgencyConsent={ showAgencyConsent }
				files={ files }
			/>
		case 2.1:
			return <ParsingStart/>
		case 3:
			return <Stage3
				ref={ stagesRefs.stage3Ref }
				loading={ loading }
				updatePosition={ updatePreferredPosition }
				updateLookingForJob={ updateLookingForJob }
				lookingForJob={ lookingForJob }
				preferences={ preferences }
			/>
		case 4:
			return <Stage4
				ref={ stagesRefs.stage4Ref }
				loading={ loading }
				updateForm={ updateLocationPreferences }
				preferences={ preferences }
			/>
		case 5:
			return <Stage5
				ref={ stagesRefs.stage5Ref }
				loading={ loading.includes('workConditions') }
				updateForm={ updateWorkConditions }
				preferences={ preferences }
			/>
		case 5.1:
			return <ParsingAwait parsingFinished={ Boolean(cvParsingResult) }/>
		case 5.2:
			return <ParsingSuccess />
		case 5.3:
			return <ParsingError backToStage2={ () => {
				setStage(2)
				setCvParsingResult(undefined)
			} }/>
		case 5.4:
			return <ParsingVerification
				links={ links }
				skills={ skills }
				education={ education }
				licences={ licences }
				languages={ languages }
				courses={ courses }
				interests={ interests }
				professionalExperience={ professionalExperience }
				setData={ updateSectionData }
			/>
		case 6:
			return <Stage6
				ref={ stagesRefs.stage6Ref }
				loading={ loading.includes('education') }
				updateForm={ updateEducation }
				education={ education }
			/>
		case 7:
			return <Stage7
				ref={ stagesRefs.stage7Ref }
				loading={ loading.includes('languages') }
				updateForm={ updateLanguages }
				languages={ languages }
			/>
		case 8:
			return <Stage8
				ref={ stagesRefs.stage8Ref }
				loading={{ skills: loading.includes('skills') , licences: loading.includes('licences') }}
				updateForm={ updateSkillsLicences }
				skills={ skills }
				licences={ licences }
			/>
		}
	}

	const sendCvToParse = async(fileId: number) => {
		loadingChange(false, 'file', true)
		await parseCv(fileId).then((result) => {
			const responseStatus = result.error ? 'error' : 'success'

			setCvParsingResult(responseStatus)
			if (responseStatus === 'success') {
				const data = result

				if (data.courses && data.courses.length > 0) {
					updateSectionData('courses', data.courses)
				}
				if (data.education && data.education.length > 0) {
					updateSectionData('education', sortEducation(data.education))
				}
				if (data.links && data.links.length > 0) {
					updateSectionData('links', data.links)
				}
				if (data.languages && data.languages.length > 0) {
					updateSectionData('languages', data.languages) }
				if (data.skills && data.skills.length > 0) {
					updateSectionData('skills', data.skills)
				}
				if (data.licences && data.licences.length > 0) {
					updateSectionData('licences', data.licences)
				}
				if (data.interests && data.interests.length > 0) {
					updateSectionData('interests', data.interests)
				}
				if (data.summary && data.summary.length > 0) {
					updateSectionData('summary', data.summary)
				}
				if (data.professionalExperience && data.professionalExperience.length > 0) {
					updateSectionData('professionalExperience', sortExperience(data.professionalExperience))
				}
			}
			if (Number(stageRef.current.value) === 5.1) {
				setTimeout(() => {
					if (responseStatus === 'success') {
						setStage(5.2)
					} else {
						setStage(5.3)
					}
				}, 1000)
			}
		})
	}

	const validateStage = async(currentStage: number): Promise<{ success: boolean, fileParsing?: boolean }> => {
		const validationResult = { success: false, fileParsing: false }
		switch (currentStage) {
		case 1:
		case 3:
		case 4:
		case 5:
		case 6:
		case 7:
		case 8:
			validationResult.success = await stagesRefs[`stage${ currentStage }Ref`].current.validateAll()
			break
		case 2:
			validationResult.success = !stagesRefs.stage2Ref.current.isFileLoading()
			validationResult.fileParsing = stagesRefs.stage2Ref.current.isFileLoading()

			if (validationResult.fileParsing) {
				loadingChange(true, 'file')

				const fileId = await stagesRefs.stage2Ref.current.getFileId()

				if (fileId) {
					validationResult.success = true
					setCvParsing(true)
					sendCvToParse(fileId)
				}
			}

			if (showOnlyParser && !validationResult.fileParsing) {
				validationResult.success = false
			}
			break
		case 5.4:
			validationResult.success = true
			break
		default:
			validationResult.success = true
			break
		}
		return validationResult
	}

	const nextAction = async(afterLoading?: boolean, fileParsing?: boolean) => {
		if (loading.length > 0) {
			setNextClicked(true)
			return
		}
		const setPage = (fileParsing?: boolean) => {
			const newPage = changePage({
				show2Stage: !parsingServiceAvailable && agencyConsent?.accepted,
				currentStage: stage,
				fileParsing,
				showOnlyParser,
				cvParsing,
				cvParsingResult
			})

			setStage(newPage.stage)
			if (newPage.maxStages) {
				setMaxStages(newPage.maxStages)
			}

			if (newPage.stage === 5.4) {
				setMaxStages(5.4)
			}

			if (stage === 5 && cvParsing) {
				setCvParsing(false)
			}
		}
		if (afterLoading) {
			setNextClicked(false)
			setPage(fileParsing)
			return
		} else {
			setNextClicked(true)
			await validateStage(stage).then((validationResult) => {
				if (validationResult.success && loading.length === 0) {
					if (validationResult.fileParsing) {
						setCvParsing(true)
					}
					setPage(validationResult?.fileParsing)
					setNextClicked(false)
				} else if (!validationResult.success) {
					setNextClicked(false)
					return null
				} else {
					return null
				}
			})
		}

		setTimeout(() => contentRef?.current?.parentElement?.scrollTo(0,0), 150)
	}

	const prevAction = async() => {
		if (stage === 3 && (cvParsing || cvParsingResult)) {
			const result = await swal(confirmParseProcessAbort)

			if (result?.dismiss as unknown as string === 'cancel') { // dismiss reason after check have type string values
				if (cvParsing) {
					setCvParsing(false)
				}
				setStage(2)
			}
		} else if ((!parsingServiceAvailable && !showAgencyConsent) && stage === 3) {
			setStage(1)
		} else if (stage === 5.1) {
			const result = await swal(confirmParseProcessAbort)

			if (result?.dismiss as unknown as string === 'cancel') { // dismiss reason after check have type string values
				if (cvParsing) {
					closeModalFn(true)
				} else {
					setStage(6)
				}
			}
		} else {
			setStage(stage - 1)
		}
	}

	const finishCreator = async() => {
		const validation = await validateStage(stage)
		if (validation.success) {
			if (stage === 5.4 || stage === 8) {
				await saveParsedData()

				window.location.pathname = '/profile'
			} else {
				closeModalFn(true)
			}
		} else {
			return null
		}
	}

	const abortParsedData = async() => {
		await swal(confirmParsedDataAbort).then((result) => {
			if (result?.value) { // dismiss reason after check have type string values
				closeModalFn(true)
			}
		})
	}

	const saveParsedData = async() => {
		const parsedData = {
			professionalExperience,
			education,
			languages,
			skills,
			licences,
			courses,
			interests,
			links,
			summary,
		}

		const completionScore = getTotalPoints(
			_.omit(
				{ ...sections, ...parsedData },
				[ 'agencyConsent', 'photo', 'lookingForJob', 'email', 'score' ]) as ProfilePointable,
		)

		await uploadParsedData(parsedData, completionScore).then((result) => {
			if (result !== 'success') {
				swal({
					type: 'error',
					title: 'Nie udało się zapisać danych w profilu.',
					text: 'Odrzuć dane z parsera i spróbuj załączyć plik ponownie.',
					confirmButtonText: 'Odrzuć'
				}).then(() => window.location.pathname = '/profile')
			} else {
				window.location.pathname = '/profile'
			}
		})
	}

	const closeModalFn = (skipAlert?: boolean) => {
		modalRef.current.closeModal(skipAlert)
		setTimeout(() => {
			ReactDOM.unmountComponentAtNode(root)
		}, 500)
	}

	return (
		<ProfileModal
			ref={ modalRef }
			closeFn={ () => confirmClose(showOnlyParser) }
			closeTitle={ showOnlyParser ? 'Odrzuć' : stage !== 5.4 ? 'Pomiń kreator' :  null }
			header={ <CreatorHeader>
				{ !showOnlyParser && (
					<picture>
						<source media="(min-width:766px)" srcSet="/praca2/assets/images/logo.svg" />
						<Logo src="/praca2/assets/images/employee/creator/logo_short.png" alt="Logo Praca.pl" />
					</picture>
				) }
				<span>{ showOnlyParser ? 'Przetwórz plik cv na profil' : 'Kreator profilu' }</span>
			</CreatorHeader> }
			belowHeader={ showOnlyParser ? null :
				<ProgressBlock>
					<StageLinearProgress variant="determinate" value={ (stage / maxStages) * 100 } />
				</ProgressBlock>
			}
			footer={{
				leftButton: stage > (showOnlyParser ? 2 : 1) && ![ 2.1, 5.2, 5.3, 5.4 ].includes(stage) ? {
					action: () => setTimeout(() => { prevAction() }, 100),
					disabled: loading.length > 0,
					text: stage === 5.1 ? 'Przerwij przetwarzanie' : 'wstecz',
					style: 'text',
					light: stage === 5.1
				} : stage === 5.4 ? {
					action: abortParsedData,
					text: 'Odrzuć',
					style: 'text',
					colorTheme: 'error',
				} : null,
				rightButton: stage !== maxStages && !([ 5.1, 5.4 ].includes(stage)) && !(showOnlyParser && [ 5.3, 5.4 ].includes(stage)) ? {
					action: () => setTimeout(() => { nextAction() }, 100),
					text: 'Dalej',
					loading: loading.length > 0
				} : stage === 5.4 ? {
					action: saveParsedData,
					text: 'Potwierdź i zapisz',
				} : stage === maxStages ? {
					action: () => setTimeout(finishCreator, 100),
					text: 'Zapisz i zakończ',
					loading: loading.length > 0
				} : null
			}}
			footerAddon={ cvParsing && stage !== 2.1 && !showOnlyParser && (
				<ParsingStatusBlock>
					<ParsingStatus>
						<PulsingIcon>
							<i className="fa-solid fa-circle fa-beat-fade"></i>
							<i className="fa-solid fa-circle fa-beat-fade --fa-beat-fade-scale: 1.075"></i>
							<i className="fa-solid fa-circle fa-beat-fade --fa-beat-fade-scale: 1.25"></i>
						</PulsingIcon>
						<div>Przetwarzanie załączonego pliku CV w toku</div>
					</ParsingStatus>
				</ParsingStatusBlock>
			) }
		>
			<>
				{ stage === 1 && (
					<picture>
						<source media="(min-width:766px)" srcSet="/praca2/assets/images/employee/creator/stage1_desktop.jpg" />
						<Stage1Image src="/praca2/assets/images/employee/creator/stage1_mobile.jpg" alt="Kreator start" />
					</picture>
				) }
				<div style={{ marginBottom: cvParsing ? 50 : 0 }} ref={ contentRef }>{ getContent() }</div>
				<input hidden readOnly ref={ stageRef } value={ stage }/>
				<input hidden readOnly ref={ nextStateRef } value={ nextClicked ? 'true' : 'false' }/>
			</>
		</ProfileModal>
	)
}

export const showCreator = (showOnlyParser? : boolean) => {
	ReactDOM.render(<UserProfileProvider><Creator showOnlyParser={ showOnlyParser }/></UserProfileProvider>, root)
}

window['showCreator'] = showCreator
