import React from 'react'
import _, { cloneDeep } from 'lodash'
import swal from 'sweetalert2'

import {
	BasicType,
	Course,
	CreatorBasicData,
	CreatorSendData,
	CvParserResult,
	Education,
	FileUploadResult,
	Language,
	Link,
	LocationType,
	LookingForJob,
	ParserData,
	Preferences,
	ProfessionalExperience,
	ProfilePointable,
	ProfileTwigData,
	WorkModel,
} from '@pages/employee/Profile/interfaces'
import { LoadingChangeFn, LoadingNames, StagesRefs } from '@pages/employee/Profile/Partials/Creator/index'
import { Stage1Field } from '@pages/employee/Profile/Partials/Creator/Stages/Stage1'
import { Stage2Field } from '@pages/employee/Profile/Partials/Creator/Stages/Stage2'
import { Stage3Field } from '@pages/employee/Profile/Partials/Creator/Stages/Stage3'
import { Stage4Field, Stage4Types } from '@pages/employee/Profile/Partials/Creator/Stages/Stage4'
import { Stage5Types } from '@pages/employee/Profile/Partials/Creator/Stages/Stage5'
import { Stage6Field, Stage6Types } from '@pages/employee/Profile/Partials/Creator/Stages/Stage6'
import { getTotalPoints } from '@pages/employee/Profile/utils'
import Fetch from '@services/Fetch'

const clearObject = (verifyObject, deleteItems?: boolean) => {
	for (const [ key, value ] of Object.entries(verifyObject)) {
		if (typeof value === 'string') {
			if (value === '') {
				deleteItems ? delete verifyObject[key] : verifyObject[key] = null
			}
		} else if (typeof value !== 'boolean' && !value) {
			deleteItems ? delete verifyObject[key] : verifyObject[key] = null
		} else if (typeof value === 'object' && value !== null) {
			clearObject(value, deleteItems)
		}
	}

	return verifyObject
}

export const getProfileData = async(): Promise<ProfileTwigData> => {
	return await Fetch('GET', '/profile')
		.then(async(result): Promise<ProfileTwigData> => {
			if (result.ok) {
				return await result.json()
			}
		})
}

export const createStartData = (): ProfileTwigData => ({
	countries: [],
	provinces: [],
	parsingServiceAvailable: false,
	agencyConsent: {
		accepted: false,
		excludedEmployers: [],
	},
	files: [],
	email: '',
	profile: {
		basicData: {
			location: {
				countryId: null,
				provinceId: null,
				city: null,
				abroadCity: null,
				address: null,
				postalCode: null
			},
			photo: null,
			firstName: null,
			lastName: null,
			phone: null,
			birthdate: null
		},
		preferences: {
			preferredPosition: {
				position: {
					title: null,
					value: 0
				},
				hasExperience: false,
				level: null,
				experienceYears: null
			},
			preferredLocation: {
				workModel: [],
				location: {
					title: null,
					type: null,
					value: 0
				},
				radius: null,
				relocation: false
			},
			expectedSalary: null,
			contractType: []
		},
		lookingForJob: 'maybe',
		professionalExperience: [],
		education: [{
			level: 'primary',
			schoolName: {
				value: 0,
				title: ''
			},
			studiesMajor: {
				value: 0,
				title: ''
			},
			stillLearning: false,
			profession: {
				value: 0,
				title: ''
			},
			degree: null
		}],
		languages: [],
		skills: [],
		licences: [],
		courses: [],
		interests: [],
		links: [],
		summary: ''
	}
})

export const getEmptyPreferences = (): Preferences => ({
	preferredPosition: {
		position: {
			title: '',
			value: 0
		},
		hasExperience: null,
		level: null,
		experienceYears: null
	},
	preferredLocation: {
		workModel: [],
		location: {
			title: '',
			type: null,
			value: 0
		},
		radius: null,
		relocation: null
	},
	expectedSalary: null,
	contractType: []
})

export const updateBlock = (newData, stateUpdateFn, isArray?: boolean) => {
	stateUpdateFn(prevData => {
		const clearedData = clearObject(clearObject(cloneDeep(prevData)))

		return isArray ? ([
			...clearedData,
			...newData,
		]) : ({
			...clearedData,
			...newData,
		})
	})
}

export const updateDataBlock = (
	oldData,
	keyToChange: Stage1Field | Stage2Field | Stage3Field | keyof Preferences | Stage4Field | Stage6Field,
	value: string | Stage4Types | Stage5Types | Stage6Types,
) => {
	const oldBlock = cloneDeep(oldData)

	oldBlock[keyToChange] = value

	return oldBlock
}

export const updateProfileData = async(dataToSend): Promise<boolean> => {
	const response = await Fetch('PATCH', '/profile', dataToSend)

	if (response.ok === true) {
		return true
	} else {
		swal({
			title: 'Przepraszamy, wystąpił błąd',
			text: 'Nie udało się zapisać podanych danych',
			type: 'error',
		})

		return false
	}
}

export const removeFromLoading = (loading: string[], fieldToRemove: string): string[] => {
	const oldData = [...loading]

	oldData.splice(loading.indexOf(fieldToRemove), 1)

	return oldData
}

export const checkLocationRequirement = (workModels: WorkModel[]): boolean => workModels.includes('remote') && workModels.length === 1

export const checkRadiusRequirement = (workModels: WorkModel[]): boolean =>
	workModels.length > 0 && workModels.includes('mobile')

export const createEmptyLanguage = (): Language => ({ name: { value: '', title: '' }, level: 'a1_beginner' })
export const createBasicObject = (): BasicType => ({ value: '', title: '' })


declare type ArrayCreatorTypes = BasicType | Education | Course | ProfessionalExperience | Link | Language | string
export const modifyArray = (oldData: Array<ArrayCreatorTypes>, value: BasicType, index: number): Array<ArrayCreatorTypes> => {
	const newData = cloneDeep(oldData)

	newData[index] = value

	return newData
}

export const removeArrayItem = (data: Array<ArrayCreatorTypes>, index: number): Array<ArrayCreatorTypes> => {
	const newData = cloneDeep(data)

	newData.splice(index, 1)

	return newData
}

export const getNotEmpty = (arrayData: BasicType[]): BasicType[] => arrayData.filter((item) => Boolean(item.title))

const toBase64 = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.onload = () => {
			resolve(reader.result as string)
		}

		reader.readAsDataURL(file)
		reader.onerror = reject
	})

export const uploadCv = async(file: File): Promise<FileUploadResult> => {
	const request = await Fetch('POST', '/employee/file/upload',
		{ file: { file: await toBase64(file), fileName: file.name }, isCv: true },
	)
	return await request.json()
}

export const parseCv = async(fileId: number): Promise<CvParserResult> => {
	const request = await Fetch('GET', '/profile/parse-cv/' + fileId)

	return await request.json() as unknown as CvParserResult
}

export const uploadParsedData = async(data: ParserData, score: number): Promise<'success' | 'error'> => {
	return await Fetch('PATCH', '/profile', { ...data, score }).then(async response => {
		if (response.ok) {
			return 'success'
		} else {
			const result = await response.json()
			console.error(result)
			return 'error'
		}
	})
}

interface SendDataOptions {
	newValue: CreatorBasicData | LookingForJob | Preferences | Education[] | Language[] | BasicType[]
	valueKeyToCheck?: string[]
	refToMatch: React.RefObject<StagesRefs>
	loadingFn: LoadingChangeFn
	name: LoadingNames
	removeNulls?: boolean
	sections: ProfilePointable
}

export const sendAgencyConsent = async(data: { enabled: boolean }) => {
	await Fetch('PATCH', '/employee/agency-consent', data)
}

export const sendData = ({ newValue, valueKeyToCheck, refToMatch, loadingFn, name, removeNulls, sections }: SendDataOptions) => {
	if (!refToMatch?.current) return

	loadingFn(true, name)
	setTimeout(async() => {
		const dataFromStage = refToMatch?.current?.getCurrentData()
		let sendData = false

		if (dataFromStage) {
			if (!valueKeyToCheck) {
				sendData = JSON.stringify(newValue) === JSON.stringify(dataFromStage)
			} else if (typeof newValue === 'string' || name !== 'preferences') {
				sendData = JSON.stringify(newValue) === JSON.stringify(dataFromStage[name])
			} else if (name === 'preferences') {
				if (JSON.stringify(newValue) === JSON.stringify(dataFromStage['preferences'])) {
					sendData = true
				}
			}
		}

		if (sendData) {
			const completionScore = getTotalPoints(
				_.omit(
					{ ...sections, [name]: newValue, },
					[ 'agencyConsent', 'photo', 'lookingForJob', 'email', 'score' ]) as ProfilePointable,
			)

			let dataToSend: CreatorSendData = { [name]: cloneDeep(newValue) }

			if (name === 'preferences') {
				dataToSend.preferences.preferredLocation.location =
					(newValue as Preferences).preferredLocation?.location?.value || null
			} else {
				dataToSend = clearObject(dataToSend, removeNulls || name === 'basicData')
			}

			await updateProfileData({ ...dataToSend, score: completionScore }).then(() => {
				loadingFn(false, name)
			})
		} else {
			loadingFn(false, name)
		}
	}, 500)
}
const radiusAllowedTypes = [ 'town', 'conurbation', 'quarter' ]
export const checkIfRadiusAllowed = (type: LocationType): boolean => radiusAllowedTypes.includes(type)

export const removeEmptyAutocomplete = (object) => {
	for (const [ key, value ] of Object.entries(object)) {
		if (JSON.stringify(value) === JSON.stringify({ value: 0, title: '' })) {
			object[key] = null
		}
	}

	return object
}

interface ChangePageFnProps {
	show2Stage?: boolean
	fileParsing?: boolean
	showOnlyParser?: boolean
	cvParsing?: boolean
	cvParsingResult?: 'success' | 'error'
	currentStage: number
}
export const changePage = ({
	show2Stage, currentStage, fileParsing, showOnlyParser, cvParsing, cvParsingResult
}: ChangePageFnProps): { stage: number, maxStages?: number } => {
	if (show2Stage && currentStage === 1) {
		return { stage: 3 }
	} else if (currentStage === 2 && fileParsing) {
		if (showOnlyParser) {
			if (cvParsingResult === 'success') {
				return { stage: 5.2, maxStages: 5.4 }
			} else if (cvParsingResult === 'error') {
				return { stage: 5.3 }
			}
			return { stage: 5.1 }
		} else {
			return { stage: 2.1 }
		}
	} else {
		switch (currentStage) {
		case 2.1:
			return { stage: 3 }
		case 5:
			if (cvParsing) {
				if (!cvParsingResult) {
					return { stage: 5.1 }
				} else if (cvParsingResult === 'success') {
					return { stage: 5.2, maxStages: 5.4 }
				} else if (cvParsingResult === 'error') {
					return { stage: 5.3 }
				}
			} else {
				return { stage: 6 }
			}
			break
		case 5.2:
			return { stage: 5.4 }
		case 5.3:
			return { stage: 6 }
		default:
			return { stage: currentStage + 1 }
		}
	}
}
